import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import { Form } from '../../components/Form';
import FormSectionWithText from '../../components/Form/FormSectionWithText';
import MediaHero from '../../components/MediaHero';
import DeviceSection from '../../components/DeviceSection';
import DemoBanner from '../../components/DemoBanner';
import SideBySideSection from '../../components/SideBySideSection';
import ResourcesSection from '../../components/ResourcesSection';
import { deviceDataFromDeviceAsset } from '../../utils/contentfulHelpers';
import SiteMeta from '../../components/SiteMeta';
import { useCookie } from 'react-use';
import Img from 'gatsby-image';
import ContentColumnItem from '../../components/ContentColumnItem';
import styled from 'styled-components';

const GenAIPage = ({ data }) => {
  const {
    metaTitle,
    metaDescription: { metaDescription },
    heroModule,
    solutionsModule1,
    solutionsModule2,
    solutionsModule3,
    demoCallout,
    contentColumns,
    featuredResourcesTitle,
    featuredResourcesSubheaderText: { featuredResourcesSubheaderText },
    featuredResourcesCtaLink,
    featuredResourcesCtaText,
    featuredResources,
    formSection
  } = data.contentfulSolutionsPe;

  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheaderText,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources,
  };

  const solutionsModule3DeviceData = deviceDataFromDeviceAsset(solutionsModule3.deviceAsset);

  const sectionsData = [
    { ...solutionsModule1, barTheme: 'orange' },
    { ...solutionsModule2, barTheme: 'yellow' },
  ];

  const IconCallout = ({ title, bodyText, icon }) => {
    return (
        <div className="icon-callout">
          <div className="icon-callout__icon">
            {icon &&
                <Img fluid={icon.fluid} alt={icon.description}/>
            }
          </div>
          <h5 className="icon-callout__title">{title}</h5>
          <p className="icon-callout__body">{bodyText.bodyText}</p>
        </div>
    );
  };
  const COOKIE_NAME = 'PWC_PROEDGE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [ value, updateCookie, deleteCookie ] = useCookie(COOKIE_NAME);

  const RedirectURL = () => {
    updateCookie(COOKIE_VALUE_UNGATED);
    if (typeof window !== 'undefined') {
      window.location = '/demo/thank-you';
    }
    return null;
  };

  return (
      <Layout>
        <SiteMeta title={metaTitle} description={metaDescription}/>
        <MediaHero {...heroModule} />
        {sectionsData.map((section, index) => {
          const layout = index % 2 ? 'mediaRight' : 'mediaLeft';
          return <SideBySideSection {...section} layoutVariant={layout} key={index}/>;
        })}
        <DeviceSection {...solutionsModule3} layoutVariant="deviceRight"
                       backgroundTheme="white" barTheme="yellow"
                       {...solutionsModule3DeviceData} />
        <section className="content-column-section">
          <div className="content-column-section__inner">
            {contentColumns.map((item, index) => {
              return <ContentColumnItem {...item} key={index}/>;
            })}
          </div>
        </section>
        {/* <DemoBanner {...demoCallout} theme="yellow" /> */}
        <a id="request-demo" name="request-demo"></a>
        <FormSectionWithText {...formSection}/>
        {/* <ResourcesSection {...resourcesData} /> */}
      </Layout>
  );
};

export default GenAIPage;

export const query = graphql`
  query {
    contentfulSolutionsPe(slug: {eq: "generative-ai"}){
      metaTitle
      metaDescription {
        metaDescription
      }
      slug
      heroModule {
        ...Navigator
      }
      solutionsModule1 {
        ...Navigator
      }
      solutionsModule2 {
        ...Navigator
      }
      solutionsModule3 {
        ...DeviceNavigator
      }
      demoCallout {
        ...Navigator
      }
      contentColumns {
        ...Navigator
        ...IconCallout
      }
      featuredResources {
        ...ResourceCard
      }
      featuredResourcesCtaText
      featuredResourcesTitle
      featuredResourcesCtaLink
      featuredResourcesSubheaderText {
        featuredResourcesSubheaderText
      }
      formSection {
        ...TextWithForm
      }
    }
  }
`;
